<template>
  <div
    class="home text-left container pb-9"
    style=""
  >
    <div class="row">
      <div class="col-12 col-sm-5">
        <h6
          class="p-3"
          style="font-weight: bold"
        >
          Bem vindo {{ user.nome }}
          <br />
          <small>
            <small>
              <b-spinner
                v-if="loadingP"
                variant="primary"
                type="grow"
                style="width: 1rem; height: 1rem"
                class="mr-1"
              />
              Última Atualização:{{ ultUpdt | moment("DD/MM/YYYY HH:mm:ss") }}
            </small>
          </small>
        </h6>
      </div>
      <div class="col-12 col-sm-4 mt-2 text-center">
        <empresa-combo
          :selecionar="changeEmpresa"
          :comTodos="true"
          :selected="user.nivel > 0 ? empresa.cod_empresa : -1"
          :disabled="loading || loadingP"
        ></empresa-combo>
      </div>
      <div class="col-12 col-sm-3 mt-2">
        <v-date-picker
          v-model="range"
          is-range
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex justify-center items-center">
              <input
                :value="`${inputValue.start} - ${inputValue.end}`"
                v-on="inputEvents.start"
                class="form-control"
              />
            </div>
          </template>
        </v-date-picker>
      </div>
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      <br />
      <br />

      <b-spinner
        variant="primary"
        type="grow"
        style="width: 4rem; height: 4rem"
      />
      <br />
      <br />
      Carregando...
    </div>
    <div
      v-if="
        ($store.state.auth.user.tipo == 'Administrador' ||
          $store.state.auth.user.tipo == 'Gerente') &&
        !loading
      "
    >
      <div v-if="user && user.super && user.super == 1">
        <home-financeiro
          :date="range"
          :empresa="empresa"
        ></home-financeiro>
      </div>

      <div class="mb-3">
        <b><b-icon-bar-chart /> Dashboard</b>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4">
          <div class="small-box bg-info">
            <div class="inner">
              <div>
                <h3>
                  <b>Pedidos</b>
                </h3>
                <div>
                  <b>Quantidade: </b>
                  {{
                    dash.qtdPedidos > dash.qtdPedidosIfood
                      ? dash.qtdPedidos - dash.qtdPedidosIfood
                      : dash.qtdPedidosIfood - dash.qtdPedidos
                  }}
                </div>
                <div>
                  <b>Vendido: </b>
                  {{
                    (dash.valorPedidos > dash.valorPedidosIfood
                      ? dash.valorPedidos - dash.valorPedidosIfood
                      : dash.valorPedidosIfood - dash.valorPedidos) | currency
                  }}
                </div>
                <div>
                  <b>Tocket Médio: </b>
                  {{ dash.ticketMedioSEMIfood | currency }}
                </div>
              </div>
            </div>
            <div class="icon">
              <i
                class="fa fa-shopping-bag"
                style="font-size: 70px"
              ></i>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4">
          <div class="small-box bg-danger">
            <div class="inner">
              <div>
                <div class="d-flex justify-content-between">
                  <h3>
                    <b>IFOOD</b>
                  </h3>
                  <div
                    class="text-right px-2"
                    style="
                      font-size: 11px;
                      background-color: rgba(0, 0, 0, 0.4);
                    "
                  >
                    ONLINE <br />
                    Qtd.:{{ dash.qtdPedidosOnline }}<br />
                    Valor: {{ dash.valorPedidosOnline | currency }}
                  </div>
                </div>
                <div><b>Quantidade: </b> {{ dash.qtdPedidosIfood }}</div>
                <div>
                  <b>Vendido: </b> {{ dash.valorPedidosIfood | currency }}
                </div>
                <div>
                  <b>Tocket Médio: </b> {{ dash.ticketMedioIfood | currency }}
                </div>
              </div>
            </div>
            <div class="icon">
              <i
                class="fa fa-cash-register"
                style="font-size: 70px"
              ></i>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4">
          <div class="small-box bg-success">
            <div class="inner">
              <div>
                <h3>
                  <b>Geral</b>
                </h3>
                <div>
                  <b>Quantidade: </b>
                  {{
                    dash.qtdPedidos +
                    (dash.somarOnline ? dash.qtdPedidosOnline : 0)
                  }}
                </div>
                <div>
                  <b>Vendido: </b>
                  {{
                    (dash.valorPedidos +
                      (dash.somarOnline ? dash.valorPedidosOnline : 0))
                      | currency
                  }}
                </div>
                <div>
                  <b>Tocket Médio: </b>
                  {{
                    dash.somarOnline
                      ? (dash.valorPedidos +
                          (dash.somarOnline ? dash.valorPedidosOnline : 0)) /
                        (dash.qtdPedidos +
                          (dash.somarOnline ? dash.qtdPedidosOnline : 0))
                      : dash.ticketMedio | currency
                  }}
                </div>
              </div>
            </div>
            <div class="icon">
              <i
                class="fa fa-dollar-sign"
                style="font-size: 70px"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 col-sm-6 text-center">
          <!-- <h5 style="font-weight: bold">Pedidos x Entrega x Horário</h5>

          <BarChart :chartData="chartEntregaHora" :options="options" /> -->
          <h5 style="font-weight: bold">Resumo Pedidos</h5>
          <hr />
          <b-row class="mb-4">
            <b-col
              cols="12"
              :lg="
                empresa_ativa &&
                empresa_ativa.configs &&
                empresa_ativa.configs.dashboard &&
                empresa_ativa.configs.dashboard.porFormaPagamento
                  ? 6
                  : 12
              "
              :style="
                empresa_ativa &&
                empresa_ativa.configs &&
                empresa_ativa.configs.dashboard &&
                empresa_ativa.configs.dashboard.porFormaPagamento
                  ? 'border-right: 1px solid #ccc'
                  : ''
              "
            >
              <b>Status</b>
              <div
                v-for="(sts, idx) in tipoStatus"
                :key="idx"
              >
                <div
                  class="cdPedido d-flex justify-content-between"
                  :style="`color:${sts.cor}`"
                >
                  <div>{{ sts.nome }}</div>
                  <div>
                    {{
                      dash.pedidosPorStatus.some((x) => x.status == sts.status)
                        ? dash.pedidosPorStatus.find(
                            (x) => x.status == sts.status
                          ).qtd
                        : 0
                    }}
                  </div>
                </div>
              </div>
              <div
                class="cdPedido d-flex justify-content-between cursor"
                :style="`background-color:red; color:white`"
                @click="openCancelados"
              >
                <div>Cancelados <small>(clique para ver)</small></div>
                <div>{{ dash.pedidosCancelados.length }}</div>
              </div>
            </b-col>

            <b-col
              cols="12"
              v-if="
                empresa_ativa &&
                empresa_ativa.configs &&
                empresa_ativa.configs.dashboard &&
                empresa_ativa.configs.dashboard.porFormaPagamento &&
                dash &&
                dash.pedidosPorFormaPagamento &&
                dash.pedidosPorFormaPagamento.length > 0
              "
              :lg="6"
            >
              <b>Forma de Pagamento</b>
              <div
                v-for="(sts, idx) in dash.pedidosPorFormaPagamento"
                :key="idx"
              >
                <div
                  class="cdPedido d-flex justify-content-between"
                  v-if="sts.pagamento"
                >
                  <div>{{ sts.pagamento }}</div>
                  <div>
                    {{ sts.valor | currency }}
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="col-12 col-sm-6 text-center">
          <h5 style="font-weight: bold">Pedidos x Tipo Entrega</h5>

          <PieChart
            :chartData="chartTipoEntrega"
            :options="options"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 col-sm-6 text-center">
          <b-btn
            block
            variant="none"
            @click="exibeRankingUsuarios = !exibeRankingUsuarios"
          >
            <div class="d-flex justify-content-between">
              <h5 style="font-weight: bold">Ranking Usuario / Pedidos</h5>
              <div>
                <b-icon-chevron-down
                  v-if="!exibeRankingUsuarios"
                  class="text-primary"
                ></b-icon-chevron-down>
                <b-icon-chevron-up
                  v-if="exibeRankingUsuarios"
                  class="text-primary"
                ></b-icon-chevron-up>
              </div>
            </div>
          </b-btn>
          <table
            v-if="exibeRankingUsuarios"
            class="table table-stripe table-hover"
            style="font-size: 11px"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Usuário</th>
                <th>Pedidos</th>
                <th>P/Hora</th>
                <th>Tempo Trabalho</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="us in topUsuarios"
                :key="us.usuario"
              >
                <td>{{ us.num }}</td>
                <td>{{ us.usuario }}</td>
                <td>{{ us.qtd }}</td>
                <td>{{ us.media }}</td>
                <td>{{ us.tempo }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="col-12 col-sm-6 pb-6"
          style=""
        >
          <b-btn
            block
            variant="none"
            @click="exibeRankingProdutos = !exibeRankingProdutos"
          >
            <div class="d-flex justify-content-between">
              <h5 style="font-weight: bold">Produto + Pedidos</h5>
              <div>
                <b-icon-chevron-down
                  v-if="!exibeRankingProdutos"
                  class="text-primary"
                ></b-icon-chevron-down>
                <b-icon-chevron-up
                  v-if="exibeRankingProdutos"
                  class="text-primary"
                ></b-icon-chevron-up>
              </div>
            </div>
          </b-btn>

          <div v-if="exibeRankingProdutos">
            <b-input
              placeholder="filtrar"
              size="sm"
              v-model="filtrarProduto"
              class="mb-4"
            ></b-input>
            <div
              v-for="(gp, idx) in proProdutoAgrupped"
              :key="idx"
            >
              <div
                class="p-2 d-flex justify-content-between mb-1 cursor hover"
                style="background-color: #ccc"
                @click="exibeGrupo(gp.Grupo, null, true)"
              >
                <h5>{{ gp.Grupo }}</h5>

                <div>
                  {{ gp.qtd }}
                  <b-icon-chevron-down
                    v-if="!gp.show"
                    class="text-primary"
                  ></b-icon-chevron-down>
                  <b-icon-chevron-up
                    v-if="gp.show"
                    class="text-primary"
                  ></b-icon-chevron-up>
                </div>
              </div>

              <b-collapse v-model="gp.show">
                <b-table
                  :items="gp.produtos"
                  :fields="[
                    { key: 'prod', label: 'Produto', sortable: true },
                    {
                      key: 'qtd',
                      label: 'Total',
                      sortable: true,
                      class: 'text-center bg-light font-weight-bold',
                    },
                    ...gp.tipos.map((tp) => {
                      return {
                        key: tp.tipo,
                        label: tp && tp.tipo ? tp.tipo.split(' ')[0] : ``,
                        sortable: true,
                        class: 'text-center',
                      };
                    }),
                  ]"
                  small
                  style="font-size: 11px"
                >
                  <template
                    v-for="tp in gp.tipos"
                    v-slot:[`cell(${tp.tipo})`]="{ item }"
                  >
                    <span :key="tp.tipo">
                      {{
                        porProdutoFiltred
                          .filter(
                            (x) =>
                              x.Grupo == gp.Grupo &&
                              x.tipo == tp.tipo &&
                              x.prod == item.prod
                          )
                          .reduce((ret, vl) => {
                            return ret + vl.qtd;
                          }, 0)
                      }}
                    </span>
                  </template>
                </b-table>
              </b-collapse>
              <!-- <b-table
              :items="gp.lista"
              :fields="[
                { key: 'prod', label: 'Produto', sortable: true },
                { key: 'qtd', label: 'Qtd.', sortable: true, class:'text-center' },
              ]"
              small
              style="font-size: 11px"
            ></b-table> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="min-height: 100px"></div>
    <b-modal
      title="Pedidos Cancelados"
      hide-footer
      id="modal-pedidos-cancelados"
      size="xl"
      small
      style="font-size: 10px"
    >
      <div
        v-if="
          dash && dash.pedidosCancelados && dash.pedidosCancelados.length > 0
        "
      >
        <b-table
          stacked="sm"
          :items="dash.pedidosCancelados"
          :fields="[
            {
              key: 'senha',
              class: 'text-center',
            },
            {
              key: 'cod_pedido',
              label: 'Código / Conta',
              class: 'text-center',
            },
            {
              key: 'tipo_entrega',
              label: 'Tipo',
              class: 'text-center',
            },
            {
              key: 'data',
              label: 'Data',
            },

            {
              key: 'itens',
              label: 'Itens',
            },
            {
              key: 'usu',
              label: 'Usuário',
            },
          ]"
          hover
        >
          <template v-slot:cell(senha)="data">
            {{ data.value }}
          </template>
          <template v-slot:cell(cod_pedido)="data">
            <div class="d-flex flex-column">
              {{ data.item.cod_pedido }}

              <div
                v-if="data.item.mesa_controle_id"
                class=""
                style="font-size: 10px"
              >
                <b>Conta n.:</b>
                {{ data.item.mesa_controle_id }}
                <br />
                {{ data.item.comanda }}
              </div>
              <div
                v-if="data.item.id_sistema"
                style="font-size: 10px"
              >
                <b>Cod. App: </b> {{ data.item.id_sistema }}
              </div>
            </div>
          </template>

          <template v-slot:cell(data)="data">
            {{ data.value | moment("DD/MM/YYYY HH:mm:ss") }}
          </template>
          <template v-slot:cell(itens)="data">
            <ul>
              <li
                v-for="(it, idx) in data.value"
                :key="idx"
              >
                {{ it.prod }}
              </li>
            </ul>
          </template>
          <template v-slot:cell(usu)="data">
            {{ data.value }}
            <small
              >(
              {{
                data.item.excluido_data | moment("DD/MM/YYYY HH:mm:ss")
              }})</small
            >
            <div
              style="font-size: 10px"
              v-if="data.item.autorizacao"
            >
              Autorizado Por: {{ data.item.autorizacao.usuario }}
              <br />
              Data / hora:
              {{
                data.item.autorizacao.dt_resposta
                  | moment("DD/MM/YYYY HH:mm:ss")
              }}
              <br />
              {{ data.item.autorizacao.obs }}
            </div>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
// import LineChart from "@/components/Charts/LineChart.js";
// import BarChart from "@/components/Charts/BarChart.js";
import PieChart from "@/components/Charts/PieChart.js";
import EmpresaCombo from "@/components/empresa/EmpresaCombo.vue";
// import BarChart from "../Charts/BarChart.js";
import PedidoLib from "@/libs/Pedido";

import moment from "moment";

import axios from "@/utils/axios";
import HomeFinanceiro from "./HomeFinanceiro.vue";
export default {
  components: {
    // BarChart,
    // BarChart,
    PieChart,
    EmpresaCombo,
    HomeFinanceiro,
  },
  name: "Home",
  data() {
    return {
      exibeRankingProdutos: window.innerWidth > 575 ? true : false,
      exibeRankingUsuarios: window.innerWidth > 575 ? true : false,
      filtrarProduto: ``,
      tipoStatus: [
        { status: 1, nome: "Á Confirmar", cor: "red" },
        { status: 2, nome: "Pendente", cor: "orange" },
        { status: 3, nome: "Pronto/Rota Entrega", cor: "blue" },
        { status: 4, nome: "Concluído", cor: "green" },
      ],
      dash: {},
      timer: null,
      loading: true,
      loadingP: false,
      data: moment().format("YYYY-MM-DD"),
      range: {
        start: new Date(),
        end: new Date(),
      },
      user: this.$store.state.auth.user,
      empresa: this.$store.state.empresa_ativa,
      empresa_ativa: this.$store.state.empresa_ativa,
      chartEntregaHora: {},
      chartTipoEntrega: {},
      pedidos: [],
      porProduto: [],
      topUsuarios: [],
      ultUpdt: moment(),
      options: {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        borderColor: "red",
        plugins: {
          legend: {
            labels: {
              // This more specific font property overrides the global property
              font: {
                size: 10,
              },
            },
          },
        },
      },
      grupoExibido: [],
    };
  },
  mounted() {
    if (this.user.tipo == "Administrador" || this.user.tipo == "Gerente") {
      this.getPedidosDia();
    }
  },
  computed: {
    porProdutoFiltred() {
      return this.porProduto.filter((x) =>
        x.prod.toLowerCase().includes(this.filtrarProduto.toLowerCase())
      );
    },
    proProdutoAgrupped() {
      const agruparProdutos = (lista) => {
        return lista.reduce((ret, vl) => {
          let has = ret.findIndex((x) => x.prod == vl.prod);
          if (has < 0) {
            ret.push({
              prod: vl.prod,
              qtd: vl.qtd,
              //tipos: agruparTipos(this.porProdutoFiltred.filter((x) => x.prod == vl.prod)),
              show: true,
              Grupo: vl.Grupo,
              tipo: vl.tipo,
            });
          } else {
            //ret[has].lista.push(vl);
            ret[has].qtd += vl.qtd;
          }
          return ret;
        }, []);
      };

      const agruparTipos = (lista) => {
        return lista.reduce((ret, vl) => {
          let has = ret.findIndex((x) => x.tipo == vl.tipo);
          if (has < 0) {
            ret.push({
              tipo: vl.tipo,
              qtd: vl.qtd,
              lista: [vl],
              show: this.exibeGrupo(vl.Grupo, vl.tipo),
            });
          } else {
            ret[has].lista.push(vl);
            ret[has].qtd += vl.qtd;
          }
          return ret;
        }, []);
      };
      const lst = this.porProdutoFiltred.reduce((ret, vl) => {
        let has = ret.findIndex((x) => x.Grupo == vl.Grupo);
        if (has < 0) {
          ret.push({
            Grupo: vl.Grupo,
            show: this.exibeGrupo(vl.Grupo, null),
            qtd: vl.qtd,
            produtos: agruparProdutos(
              this.porProdutoFiltred.filter((x) => x.Grupo == vl.Grupo)
            ),
            tipos: agruparTipos(
              this.porProdutoFiltred.filter((x) => x.Grupo == vl.Grupo)
            ),
          });
        } else {
          // ret[has].lista.push(vl);
          ret[has].qtd += vl.qtd;
        }
        return ret;
      }, []);

      return lst;
    },
  },
  beforeDestroy() {
    try {
      clearInterval(this.timer);
    } catch {
      console.log("err clear interval");
    }
    this.timer = null;
  },
  watch: {
    data: function () {
      this.getPedidosDia();
    },
    empresa: function () {
      this.getPedidosDia();
    },
    range: function () {
      this.getPedidosDia();
    },
  },
  methods: {
    openCancelados() {
      this.$bvModal.show("modal-pedidos-cancelados");
    },
    async changeEmpresa(emp) {
      // console.log(888, emp);
      this.empresa = emp;
      try {
        clearInterval(this.timer);
      } catch {
        console.log("erro timer");
      }
      this.getPedidosDia();
    },
    async getPedidosDia(load = true) {
      try {
        clearInterval(this.timer);
        this.timer = null;
      } catch {
        console.log("erro timer");
      }

      this.ultUpdt = moment();
      if (load) this.loading = true;
      else this.loadingP = true;

      // console.log("start");
      let dash = await PedidoLib.getDashboard({
        data: this.range.start,
        cod_empresa: this.empresa.cod_empresa,
        dataAte: this.range.end,
      });
      this.dash = dash;
      console.log("dash", dash);

      let tipos_entrega = dash.pedidosPorTipoEntrega.map((x) => ({
        tp: x.tipo_entrega,
        cor:
          x.tipo_entrega == "ENTREGA"
            ? "#8C0000"
            : x.tipo_entrega == "BUSCAR"
            ? "#aeaeae"
            : x.tipo_entrega == "MESA"
            ? "orange"
            : "green",
      }));

      this.chartTipoEntrega = {};
      this.chartTipoEntrega.labels = tipos_entrega.map((tp) => tp.tp);
      this.chartTipoEntrega.datasets = [
        {
          label: "Pedidos",
          data: tipos_entrega.map((tp) => {
            let has = dash.pedidosPorTipoEntrega.find(
              (x) => x.tipo_entrega == tp.tp
            );
            return has ? has.qtd : 0;
          }),
          backgroundColor: tipos_entrega.map((tp) => tp.cor),
        },
      ];
      let q = 0;
      this.topUsuarios = this.dash.pedidosPorUsuario
        .sort((a, b) => {
          return a.qtd > b.qtd ? -1 : 1;
        })
        .map((it) => {
          q++;
          return {
            num: q,
            ...it,
          };
        });

      this.topUsuarios.map((us) => {
        let mins = moment(us.ultimoPedido).diff(us.primeiroPedido, "hours");
        us.tempo = new Date(
          moment(us.ultimoPedido).diff(moment(us.primeiroPedido), "seconds") *
            1000
        )
          .toISOString()
          .substr(11, 8);
        us.media = (mins > 0 ? us.qtd / mins : 0).toFixed(2);
      });

      try {
        this.porProduto = (
          await axios.post("/pedidos/resumo_produtos", {
            data: this.range.start,
            dataAte: this.range.end,
            cod_empresa: this.empresa.cod_empresa,
          })
        ).data[0];

        // console.log("porprd", this.porProduto);
      } catch {
        console.log("error on por produto");
      }
      try {
        clearInterval(this.timer);
      } catch {
        console.log("erro timer");
        ``;
      }
      this.timer = setTimeout(() => {
        this.getPedidosDia(false);
      }, 15000);

      // this.dash.qtdPedidosIfood = this.dash.p;

      this.loading = false;
      this.loadingP = false;

      // console.log(this.chartTipoEntrega);
    },
    exibeGrupo(grupo, tipo, setar) {
      let has = this.grupoExibido.findIndex(
        (x) => x.grupo == grupo && x.tipo == tipo
      );
      if (setar) {
        if (has < 0) {
          this.grupoExibido.push({ grupo, tipo, show: true });
        } else {
          this.grupoExibido[has].show = this.grupoExibido[has].show
            ? false
            : true;
        }
      }
      let show = this.grupoExibido.find(
        (x) => x.grupo == grupo && x.tipo == tipo
      )
        ? this.grupoExibido.find((x) => x.grupo == grupo && x.tipo == tipo).show
        : false;
      // console.log(
      //   "gp",
      //   grupo,
      //   tipo,
      //   this.grupoExibido.find((x) => x.grupo == grupo && x.tipo == tipo),
      //   show
      // );
      return this.filtrarProduto && this.filtrarProduto.trim() != ``
        ? true
        : show;
    },
  },
};
</script>

<style lang="sass" scoped>

@import "@/assets/scss/home.scss"
</style>
